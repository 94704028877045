<!-- 
	@component
	
	NAME AND LOGO COMPONENT
	
	@prop {CssClasses} classes - Additional classes to add to the name and logo container
	@prop {CssClasses} containerClasses - Additional classes to add to the name and logo container
	@prop {boolean} isMobileDrawerLogo - Whether or not the name and logo is being used in the mobile drawer
	@prop {boolean} displayBetaTag - Whether or not to display the beta tag
	@prop {LogoProps} logoProps - Props to pass to the logo component
	@prop {string} testid - Test id for the name and logo container
 -->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import Logo, { type LogoSvgProps, type LogoWrapperProps } from './Logo.svelte';

	export let classes: CssClasses = '';
	export let containerClasses: CssClasses = '';
	export let isMobileDrawerLogo: boolean = false;
	export let displayBetaTag: boolean = false;
	export let logoProps: {
		svgProps?: LogoSvgProps;
		wrapperProps: LogoWrapperProps;
	} = {
		svgProps: {
			height: 24,
			width: 40
		},
		wrapperProps: {
			classes: '',
			height: 'h-8',
			width: 'w-10'
		}
	};
	export let testid: string = 'name-and-logo';
</script>

<div
	data-testid={testid}
	class="relative flex flex-col items-center justify-center tablet:flex-row tablet:items-start tablet:justify-start {containerClasses}"
	class:!flex-row={isMobileDrawerLogo}
	class:!items-start={isMobileDrawerLogo}
>
	<div class="flex items-center gap-x-1 text-primary-400">
		<Logo svgProps={logoProps.svgProps} wrapperProps={logoProps.wrapperProps} />
		<span class="text-2xl font-bold tracking-tighter tablet:pr-2 {classes}"> Tickrr </span>
	</div>

	{#if displayBetaTag}
		<span
			class="
			ml-2
			text-xs
			font-bold
			tracking-widest
			
			"
		>
			BETA
		</span>
	{/if}
</div>
